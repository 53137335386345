export const APP_VERSION = "6.155.0";
export const APP_NAME = "lawnstarter-customer-web";
export const BRAND_SLUG = "lawnstarter";
export const GOOGLE_API_KEY = "AIzaSyCtW4KAA_kr-LPbnH6EMPwOsnjvxqXTVuk";
export const GOOGLE_FIREBASE_SENDER_ID = "162748323610";
export const CANCELLATION_SUPPORT_PHONE_NUMBER = "1-855-577-9573";
export const CANCELLATION_ZENDESK_WEB_CHANNEL_KEY = "9ea70407-699c-427c-b6ef-cf6d18950947";
export const CANCELLATION_ZENDESK_IOS_CHANNEL_KEY = "eyJzZXR0aW5nc191cmwiOiJodHRwczovL2xhd25zdGFydGVyMS56ZW5kZXNrLmNvbS9tb2JpbGVfc2RrX2FwaS9zZXR0aW5ncy8wMUoyQzhIR040U0JRS1laNENXVjlYWEsxSy5qc29uIn0=";
export const CANCELLATION_ZENDESK_ANDROID_CHANNEL_KEY = "eyJzZXR0aW5nc191cmwiOiJodHRwczovL2xhd25zdGFydGVyMS56ZW5kZXNrLmNvbS9tb2JpbGVfc2RrX2FwaS9zZXR0aW5ncy8wMUoyQ0I5SkIyVzg4NkJUQkZYSlJBU1E2Ni5qc29uIn0=";
export const ZENDESK_IOS_CHANNEL_KEY = "eyJzZXR0aW5nc191cmwiOiJodHRwczovL2xhd25zdGFydGVyMS56ZW5kZXNrLmNvbS9tb2JpbGVfc2RrX2FwaS9zZXR0aW5ncy8wMUhIWVdXS0JCSzUyMjAwWkhOQkFOQ0hWTS5qc29uIn0=";
export const ZENDESK_ANDROID_CHANNEL_KEY = "eyJzZXR0aW5nc191cmwiOiJodHRwczovL2xhd25zdGFydGVyMS56ZW5kZXNrLmNvbS9tb2JpbGVfc2RrX2FwaS9zZXR0aW5ncy8wMUhIWVdaUEc5TjVaQ1lCWlhRVlhQRU04Ni5qc29uIn0=";
export const ZENDESK_WEB_CHANNEL_KEY = "ed3b5572-9bbc-4e3b-add8-4bf23540f92b";
export const BRAZE_ENDPOINT = "sdk.iad-05.braze.com";
export const SHARE_ON_NEXTDOOR_TIMEOUT_MINUTES = "5";
export const ENV = "staging";
export const SENTRY_ENVIRONMENT = "staging";
export const SENTRY_PROJECT_SLUG = "https://7a64fe4d24c140af9099be12c5818127@sentry.io/1367049";
export const CUST_WEB_BASE_URL = "https://dev-my.lawnstarter.com";
export const API_BASE_URL = "https://dev-api.lawnstarter.com";
export const SIGNUP_BASE_URL = "https://dev-signup.lawnstarter.com";
export const PARTNERS_BASE_URL = "https://dev-partners-api.lawnstarter.com";
export const SEGMENT_KEY = "wyRJQYEsmnjRT5YbuBmgwFWUpZpMbikm";
export const STRIPE_KEY = "pk_test_Gwo1qUvFZUQrufioqsESkESd";
export const LEGACY_CUST_WEB_BASE_URL = "https://dev-legacy-my.lawnstarter.com";
export const FLAGSMITH_ENVIRONMENT_KEY = "XySXXtqt5UfgpiNtWgfXiC";